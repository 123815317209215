import React from "react";
import { GlobalContext } from "../../context/GlobalState";
import { CSVLink } from "react-csv";
import {
  Paper, Grid, Typography, Box, Button, MenuItem, FormControl, Select,
  InputLabel, CircularProgress, TableHead, Dialog, Table, DialogActions,
  DialogContent, DialogContentText, DialogTitle, TableBody, TableCell, TableRow
  } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

export default function Enquiry() {
  const [back, setBack] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [dbutton, setDbutton] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [startdate, setStartdate] = React.useState(new Date());
  const [enddate, setEnddate] = React.useState(new Date());
  const [centercode, setCentercode] = React.useState("");
  const { allcentercode, getCenterCodeDetails } = React.useContext( GlobalContext);
  const {
    enquiryStatement,
    getEnquiryData,
    err
  } = React.useContext(GlobalContext);

  const handleClose = () => {
    setOpen(false);
    setBack(true);
  };

  const handleStartDateChange = date => {
    setStartdate(date);
    setEnddate(date);
  };

  const handleEndDateChange = date => {
    setEnddate(date);
  };

  

React.useEffect(() => {
    getCenterCodeDetails();
  }, []);

  const changeCenterCode = (e) => {
    setCentercode(e.target.value);
  };

  const onChangeUser = e => {
    const value = e.target.value ? e.target.value : "";
    setUser(value);
  };

  const headCells = [
    { id: 'center', numeric: false,  label: 'Center Code' },
    { id: 'credit', numeric: false,  label: 'Session' },
    { id: 'payment', numeric: false,  label: 'Name' },
    { id: 'Gender', numeric: false,  label:'Gender'},
    { id: 'Program', numeric: false,  label:'Program'},
    { id: 'Email', numeric: false,  label:'Email'},
    { id: 'Mobile', numeric: false,  label:'Mobile No.'},
    { id: 'DOB', numeric: false,  label:'DOB'},
    { id: 'Enquired', numeric: false,  label:'Enquired On'},
    { id: 'Father', numeric: false,  label:'Father\'s Name'},
    { id: 'Mother', numeric: false,  label:'Mother\'s Name'},
    { id: 'Source', numeric: false,  label:'Source'},
    { id: 'otherSource', numeric: false,  label:'Other Source'}
  ];

  const headCells2 = [
    { id: 'center', numeric: false,  label: 'Center Code' },
    { id: 'Email', numeric: false,  label:'Email'},
    { id: 'Mobile', numeric: false,  label:'Mobile No.'},
    { id: 'Enquired', numeric: false,  label:'Enquired On'}
  ];

  const onSubmit = async e => {
    setDbutton(true);
    setLoading(true);
    e.preventDefault();
    const statement = {
      type: user,
      centercode: centercode,
      fromDate: moment(startdate).format("YYYY-MM-DD"),
      toDate: moment(enddate).format("YYYY-MM-DD")
    };
    await getEnquiryData(statement);
    setDbutton(false);
    setLoading(false);
  };
  let filterPaymentData = enquiryStatement.filter((item)=>(
    item[0] !== "Center Code"
  ))
  
  const csvData = enquiryStatement;

  return (
    <React.Fragment>
      <Box boxShadow={3} mb={1}>
        <form onSubmit={onSubmit}>
          <Grid container component={Paper}>
            <Grid
              item
              xs={12}
              style={{ background: "antiquewhite", marginBottom: "16px" }}
            >
              <Box p={2}>
                <Typography variant="h6">Enquiry Statement</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box px={1} mt={2} pl={2}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="user">Type</InputLabel>
                  <Select
                    labelId="user"
                    value={user}
                    onChange={onChangeUser}
                    name="user"
                    label="User"
                  >
                    <MenuItem value={"request"}>Requests</MenuItem>
                    <MenuItem value={"response"}>Repsonses</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>


    <Grid item xs={12} sm={3}>
        <Box px={2} mt={2}>
          <FormControl variant='outlined' required fullWidth>
            <InputLabel id='demo-simple-select-filled-label12' required>
              Center Code
            </InputLabel>
            <Select
              labelId='demo-simple-select-filled-label12'
              id='demo-simple-select-filled12'
              value={centercode}
              onChange={changeCenterCode}
              name='centerCode'
              label='Center Code'
            >
                <MenuItem key='all' value='all'>
                  All
                </MenuItem>
              {allcentercode.map((code) => (
                <MenuItem key={code._id} value={code.centerCode}>
                  {code.centerCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>

          <Grid item xs={12} md={3}>
            <Box px={2} bgcolor="white" display="flex" justifyContent="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Log Start Date"
              value={startdate}
              name="startdate"
              onChange={handleStartDateChange}
            />
            </MuiPickersUtilsProvider>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box px={2} bgcolor="white" display="flex" justifyContent="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Log End Date"
              value={enddate}
              name="enddate"
              onChange={handleEndDateChange}
            />
            </MuiPickersUtilsProvider>
            </Box>
          </Grid>

            <Grid item xs={12}>
            <Box p={2}>
            <Button style={{float:"right"}}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  {loading ? (
                    <>
                      Loading
                      <CircularProgress
                        style={{ color: "#fff", marginLeft: "15px" }}
                        size={20}
                      />
                    </>
                  ) : (
                    "Generate Statement"
                  )}
                </Button>
              <Button
                disabled={dbutton}
                id="download"
                size="large"
                variant="contained"
                color="primary"
              >
                <CSVLink
                  data={csvData}
                  filename={`Enquiry-${user}-statement-${moment().format("DD-MM-YYYY")}.csv`}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Download{" "}
                </CSVLink>
              </Button>
              
            </Box>
            </Grid>
          </Grid>
        </form>
        {filterPaymentData.length && user === 'response'  ?
        <div style={{margin: "15px", border: "1px solid", borderRadius: "6px"}}>
        <Table style={{backgroundColor: "#fff"}}>
        <TableHead style={{backgroundColor: "#3f51b5", color: "#fff"}}>
      <TableRow style={{color: "#fff"}}>
      {headCells.map((headCell) => (
          <TableCell style={{color: "#fff"}}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >        
          {headCell.label}
          </TableCell>
        ))}

      </TableRow>
      </TableHead>
      <TableBody>
      {filterPaymentData.map((value, index)=>{
  return (
  <TableRow key={index+1}>
  <TableCell align="center">{value[0] ? value[0] : 'N/A'}</TableCell>
  <TableCell align="center">{value[1] ? value[1] : 'N/A'}</TableCell>
  <TableCell align="center">{value[2] ? value[2] : 'N/A'}</TableCell>
  <TableCell align="center">{value[3] ? value[3] : 'N/A'}</TableCell>
  <TableCell align="center">{value[4] ? value[4] : 'N/A'}</TableCell>
  <TableCell align="center">{value[5] ? value[5] : 'N/A'}</TableCell>
  <TableCell align="center">{value[6] ? value[6] : 'N/A'}</TableCell>
  <TableCell align="center">{value[7] ? value[7] : 'N/A'}</TableCell>
  <TableCell align="center">{value[8] ? value[8] : 'N/A'}</TableCell>
  <TableCell align="center">{value[9] ? value[9] : 'N/A'}</TableCell>
  <TableCell align="center">{value[10] ? value[10] : 'N/A'}</TableCell>
  <TableCell align="center">{value[11] ? value[11] : 'N/A'}</TableCell>
  <TableCell align="center">{value[12] ? value[12] : 'N/A'}</TableCell>
  </TableRow>   
  );
})}
</TableBody>
</Table></div> : null }

{filterPaymentData.length && user === 'request' ? <div style={{margin: "15px", border: "1px solid", borderRadius: "6px"}}>
        <Table style={{backgroundColor: "#fff"}}>
        <TableHead style={{backgroundColor: "#3f51b5", color: "#fff"}}>
      <TableRow style={{color: "#fff"}}>
      {headCells2.map((headCell) => (
          <TableCell style={{color: "#fff"}}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >        
          {headCell.label}
          </TableCell>
        ))}

      </TableRow>
      </TableHead>
      <TableBody>
      {filterPaymentData.map((value, index)=>{
  return (
      <TableRow key={index+1}>
      <TableCell align="center">{value[0] ? value[0] : 'N/A'}</TableCell>
      <TableCell align="center">{value[1] ? value[1] : 'N/A'}</TableCell>
      <TableCell align="center">{value[2] ? value[2] : 'N/A'}</TableCell>
      <TableCell align="center">{value[3] ? value[3] : 'N/A'}</TableCell>
      </TableRow>
  );
})}
</TableBody>
</Table></div> : null}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          align="center"
          style={{ background: "antiquewhite" }}
          id="responsive-dialog-title"
        >
          {err ? err : "Enquiry report is generated"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClose} variant="contained" color="primary">
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
